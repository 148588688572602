import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const fonts = { mono: `'Menlo', monospace` }

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
})

const theme = extendTheme({ 
  fonts: {
    heading: `'Figtree Variable', sans-serif`,
    body: `'Figtree Variable', sans-serif`,
  },
  components: {
    Button: {
        // 1. We can update the base styles
        baseStyle: {
          color: "#000000",
          backgroundColor: '#F39F50', // Normally, it is "semibold"
          _hover: {
            opacity: "0.8"
          },
        },
      colors: {
        black: '#16161D',
      }
    }
  },
  breakpoints,
})

export default theme
