import { ChakraProvider } from '@chakra-ui/react'
import { SessionProvider } from "next-auth/react"

import dynamic from "next/dynamic";
import theme from '../theme'
import { AppProps } from 'next/app'
import { MantineProvider } from '@mantine/core';
import React, { useState, useRef } from "react";

import '../css/toast-styling.css';

import Head from 'next/head'
import TagManager from "react-gtm-module"
import '@tremor/react/dist/esm/tremor.css'

import NextNProgress from 'nextjs-progressbar';

import '@fontsource-variable/figtree';





const tagManagerArgs = {
  gtmId: "GTM-5VKNC2S",
}


function SafeHydrate({ children }) {
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  )
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  })
  const AnyComponent = Component as any;
  return (
    <SafeHydrate>
      <SessionProvider session={session}>
        <ChakraProvider resetCSS theme={theme}>
            <MantineProvider>
              <Head>
                <link rel="apple-touch-icon" sizes="57x57" href="/img/icon/apple-icon-57x57.png"/>
                <link rel="apple-touch-icon" sizes="60x60" href="/img/icon/apple-icon-60x60.png"/>
                <link rel="apple-touch-icon" sizes="72x72" href="/img/icon/apple-icon-72x72.png"/>
                <link rel="apple-touch-icon" sizes="76x76" href="/img/icon/apple-icon-76x76.png"/>
                <link rel="apple-touch-icon" sizes="114x114" href="/img/icon/apple-icon-114x114.png"/>
                <link rel="apple-touch-icon" sizes="120x120" href="/img/icon/apple-icon-120x120.png"/>
                <link rel="apple-touch-icon" sizes="144x144" href="/img/icon/apple-icon-144x144.png"/>
                <link rel="apple-touch-icon" sizes="152x152" href="/img/icon/apple-icon-152x152.png"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/img/icon/apple-icon-180x180.png"/>
                <link rel="icon" type="image/png" sizes="192x192"  href="/img/icon/android-icon-192x192.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/img/icon/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="96x96" href="/img/icon/favicon-96x96.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/img/icon/favicon-16x16.png"/>
                <link rel="manifest" href="/img/icon/manifest.json"/>
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="msapplication-TileImage" content="/img/icon/ms-icon-144x144.png"/>
                <meta name="theme-color" content="#ffffff"/>
                <meta name="apple-mobile-web-app-title" content="Rekening" />
                <meta name="application-name" content="Rekening" />
              </Head>
              <NextNProgress  color="#F39F50" />
              <AnyComponent {...pageProps} />
            </MantineProvider>
        </ChakraProvider>
      </SessionProvider>
    </SafeHydrate>
  )
}

export default dynamic(() => Promise.resolve(MyApp), {
  ssr: false,
});